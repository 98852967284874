<script setup lang="ts">
const __MACROS_props = defineProps({
  item: { ...{ required: false } },
  to: { ...{ required: false } },
  shopLink: { ...{ required: false } },
});

import { toRef as __MACROS_toRef } from "vue";
import { getItemUrl } from '@auctelia/url-maker'
import type { ItemCore } from '#types/entities/item'
import type { ShopConfig } from '@/utils/shop'

const router = useRouter()
const { locale } = useI18n()
const userStore = useUserStore()
const dataStore = useDataStore()

const item = __MACROS_toRef(__props, "item")
const path = __MACROS_toRef(__props, "to")
const shopLink = __MACROS_toRef(__props, "shopLink")

const marketplaceUrl = computed(() => dataStore.currentShop?.meta.endpoints.web ?? '')

function redirect() {
  if (!canPerformOnItem('show', item.value?.status)) return

  if (item.value)
    return getItemUrl(
      marketplaceUrl.value,
      locale.value,
      getLocalizedValue(item.value.translations, 'name'),
      item.value.itemShortId
    )

  if (path.value)
    return `${marketplaceUrl.value}/${path.value}`

  if (shopLink.value)
    return `${marketplaceUrl.value}/${dataStore.shopConfig?.links[shopLink.value]}`

  if (router.currentRoute.value.query?.callback) {
    const callback = router.currentRoute.value.query?.callback?.toString()
    const { url } = buildRedirectUrlWithToken(userStore.beaconToken, callback)
    return url
  }

  return marketplaceUrl.value
}
</script>

<template>
  <NuxtLink
    data-testid="marketplace-link"
    :to="redirect()"
    external
    target="_blank"
    class="no-underline"
  >
    <slot />
  </NuxtLink>
</template>
